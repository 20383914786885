import { Privilege } from "../services/EmployeeService";
import PrivilegeCard from "../components/PrivilegeCard/PrivilegeCard";
import './PrivilegeList.css';

type Props = {
  privileges: Privilege[]
  removePrivilege: (privilegeId: number) => void;
}

const PrivilegesList = ({ privileges, removePrivilege }: Props) => {
  return (
    <div className="privilege-section">
      <div className="title-container">
        <h4>Choose your Privilege</h4>
        <p>Click to access to wallet link</p>
      </div>
      <div className="grid-container">
        {privileges.map(privilege => <PrivilegeCard key={privilege.id} privilege={privilege} removePrivilege={removePrivilege} />)}
      </div>
    </div>
  )
}

export default PrivilegesList;

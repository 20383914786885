import { memo, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { getEmployeeById, Privilege } from "../../services/EmployeeService";
import PrivilegesList from "../../auxiliary-components/PrivilegesList";
import './PrivilegePage.css';

const PrivilegePage = () => {
  const [privileges, setPrivileges] = useState<Privilege[]>([]);
  const { employeeId } = useParams();

  const fetchEmployee = async (employeeId: string) => {
    const employee = await getEmployeeById(employeeId);
    setPrivileges(employee.privileges);
  }

  const removePrivilege = (privilegeId: any) => {
    setPrivileges(prev => prev.filter(privilege => privilege.id !== privilegeId))
  }

  useEffect(() => {
    if (employeeId) {
      fetchEmployee(employeeId);
    }
  }, [employeeId])

  return (
    <div className="container">
      <header>
        <img src="logo.png" alt="premium-club-logo" width={'80%'} height={'100%'} />
      </header>
      <div className="secondary-header">
        <p className="secondary-header-title">Welcome to Premium Club</p>
      </div>
      <PrivilegesList privileges={privileges} removePrivilege={removePrivilege} />
    </div>
  )
}

export default memo(PrivilegePage);

import { memo } from "react";
import { Privilege } from "../../services/EmployeeService";
import { useNavigate } from "react-router-dom";
import './PrivilegeCard.css';

type Props = {
  privilege: Privilege;
  removePrivilege: (privilegeId: number) => void;
}

const PrivilegeCard = ({ privilege, removePrivilege }: Props) => {
  const { id, name, imageUrl, pass_id } = privilege;
  const navigate = useNavigate();

  const onClickHandler = () => {
    removePrivilege(id);
    navigate(`/pass/verify?code=${pass_id}`)
  }
  
  return (
    <div style={{ backgroundImage: imageUrl }} className="card" onClick={onClickHandler}>
      <div className="card-content">
        <p className="card-title">{name}</p>
        <button
          className="link"
          onClick={onClickHandler}
        >
          Click to wallet
        </button>
      </div>
    </div>
  )
}

export default memo(PrivilegeCard);
